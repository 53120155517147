const colorSchemeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

function handleDarkMode(event) {
	const isDark = event.matches;
	const favicon = document.querySelector(`link[rel="icon"]`);
	
	if(isDark) {
		favicon.href = "/favicon-dark.svg";
		return;
	}
	
	favicon.href = "/favicon-light.svg";
}

function initFaviconHandler() {
	handleDarkMode(colorSchemeMediaQuery);
	colorSchemeMediaQuery.addListener(handleDarkMode)
}


export default initFaviconHandler;
import App from "./App.svelte";
import initFaviconHandler from './utils/faviconHandler';
import "scroll-behavior-polyfill";

initFaviconHandler();

const app = new App({
	target: document.body
});

export default app;
<script>
    export let src = "";
    export let alt = "";
</script>

<style>
    .polaroid {
        display: block;
        background-color: #fff;
        padding: 20px 20px 60px 20px;
        transform: rotate(10deg);
        transition: transform .4s;
        user-select: none;
        box-shadow: 2px 12px 36px rgba(0, 0, 0);
    }
    .polaroid-img {
        display: block;
        max-height: 380px;
        filter: grayscale(1);
    }
    .polaroid-caption {
        font-size: 1.6rem;
        font-family: var(--font-raleway);
        visibility: hidden;
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 60px;
        left: 0;
        padding: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity .4s;
    }

    .polaroid:hover {
        transform: rotate(0) scale(110%);
    }
    .polaroid:hover .polaroid-img {
        filter: grayscale(0);
    }
    .polaroid:hover .polaroid-caption {
        visibility: visible;
        opacity: 1;
    }

    @media (max-width: 980px) {
        .polaroid {
            transform: rotate(10deg) scale(70%);
        }
        .polaroid:hover {
            transform: rotate(0) scale(80%);
        }
    }
</style>

<figure class="polaroid">
    <img {src} {alt} class="polaroid-img">
    <figcaption class="polaroid-caption">Me at the highlands in southern Brazil</figcaption>
</figure>
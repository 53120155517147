<script>
    export let type;
</script>

<style>
    .title {
        font-size: 5rem;
        color: var(--color-text-primary);
    }

    @media (max-width: 980px) {
        .title {
            font-size: 3rem;
        }
    }
</style>

{#if type === "primary"}
    <h1 class="title">
        <slot />
    </h1>
{:else if type === "secondary"}
    <h2 class="title">
        <slot />
    </h2>
{/if}
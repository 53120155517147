<script>
    import Title from "../components/Title.svelte";
    import Text from "../components/Text.svelte";
    import Spacer from "../layout/Spacer.svelte";
    import Polaroid from "../components/Polaroid.svelte";
</script>

<style>
    .splash {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
    }

    @media (max-width: 980px) {
        .splash {
            flex-direction: column;
            gap: 20px;
        }
    }
</style>

<div class="splash">
    <div class="splash-info">
        <Title type="primary">Hi, I'm Lucas</Title>
        <Spacer vertical={16} />
        <Text>I'm a developer, student and digital craftsman</Text>
    </div>
    <Polaroid src="image/me.png" />
</div>
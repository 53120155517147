<script>
    import Splash from "./sections/Splash.svelte";
    import Footer from "./sections/Footer.svelte";

	window["🐶"] = () => alert("WOOF WOOF");
</script>

<style>
	.wrapper {
		max-width: 1400px;
		margin: 0 auto 20px;
		padding-left: env(safe-area-inset-left);
		padding-right: env(safe-area-inset-right);
		padding-bottom: env(safe-area-inset-bottom);
    }
</style>

<div class="wrapper">
	<main class="wrapper-main">
		<Splash />
        <Footer />
	</main>
</div>